
import {defineComponent} from 'vue';
import TaskForm from '@/components/task/TaskForm.vue';

export default defineComponent({
  name: 'TaskDetailTabOverview',
  components: {
    TaskForm,
  },
  setup() {
    return {};
  },
});
