
import {defineComponent, PropType} from 'vue';
import {buttonProps} from './Button.vue';
import Button from '@/components/button/Button.vue';

export const faIconButtonProps = {
  icon: {
    type: [Array, String] as PropType<Icon>,
    required: true,
  },
  badgeIcon: {
    type: [Array, String] as PropType<Icon>,
    required: false,
  },
  ...buttonProps,
};

export default defineComponent({
  name: 'FaIconButton',
  components: {Button},
  props: faIconButtonProps,
  emits: [
    'click',
  ],
  setup() {
    return {};
  },
});
