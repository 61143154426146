
import {computed, defineComponent, watch} from 'vue';
import {useStore} from 'vuex';
import useSpider from '@/components/spider/spider';
import useNode from '@/components/node/node';
import Form from '@/components/form/Form.vue';
import FormItem from '@/components/form/FormItem.vue';
import InputWithButton from '@/components/input/InputWithButton.vue';
import CheckTagGroup from '@/components/tag/CheckTagGroup.vue';
import {TASK_MODE_SELECTED_NODE_TAGS, TASK_MODE_SELECTED_NODES} from '@/constants/task';
import useRequest from '@/services/request';
import useTask from '@/components/task/task';
import TaskStatus from '@/components/task/TaskStatus.vue';
import Tag from '@/components/tag/Tag.vue';
import FaIconButton from '@/components/button/FaIconButton.vue';
import {useRouter} from 'vue-router';
import {isCancellable} from '@/utils/task';
import {ElMessage, ElMessageBox} from 'element-plus';
import {isZeroObjectId} from '@/utils/mongo';
import useTaskDetail from '@/views/task/detail/taskDetail';

const {
  post,
} = useRequest();

export default defineComponent({
  name: 'TaskForm',
  components: {
    FaIconButton,
    Tag,
    TaskStatus,
    Form,
    FormItem,
    InputWithButton,
    CheckTagGroup,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // router
    const router = useRouter();

    // store
    const ns = 'task';
    const store = useStore();

    // use node
    const {
      allListSelectOptionsWithEmpty: allNodeSelectOptions,
      allTags: allNodeTags,
    } = useNode(store);

    // use spider
    const {
      allListSelectOptions: allSpiderSelectOptions,
    } = useSpider(store);

    // use task
    const {
      form,
      allSpiderDict,
      modeOptionsDict,
    } = useTask(store);

    // use task detail
    const {
      activeId,
    } = useTaskDetail();

    // use request
    const {
      get,
    } = useRequest();

    // watch spider id
    watch(() => {
      const task = form.value as Task;
      return task.spider_id;
    }, async () => {
      const task = form.value as Task;
      if (!task.spider_id) return;
      const res = await get<any, Spider>(`/spiders/${task.spider_id}`);
      task.cmd = res.data.cmd;
      task.param = res.data.param;
    });

    const getSpiderName = (id: string) => {
      const spider = allSpiderDict.value.get(id) as Spider;
      return spider?.name;
    };

    const getModeName = (id: string) => {
      const op = modeOptionsDict.value.get(id) as SelectOption;
      return op?.label;
    };

    const onGoToSpider = () => {
      router.push(`/spiders/${form.value.spider_id}`);
    };

    const onGoToNode = () => {
      router.push(`/nodes/${form.value.node_id}`);
    };

    const cancellable = computed<boolean>(() => isCancellable(form.value.status));

    const onCancel = async () => {
      await ElMessageBox.confirm('Are you sure to cancel?', 'Cancel', {type: 'warning'});
      await ElMessage.info('Attempt to cancel');
      try {
        await post(`/tasks/${activeId.value}/cancel`);
      } finally {
        await store.dispatch(`${ns}/getById`, activeId.value);
      }
    };

    const noNodeId = computed<boolean>(() => isZeroObjectId(form.value.node_id));

    return {
      ...useTask(store),

      // custom
      TASK_MODE_SELECTED_NODES,
      TASK_MODE_SELECTED_NODE_TAGS,
      allNodeSelectOptions,
      allNodeTags,
      allSpiderSelectOptions,
      getSpiderName,
      getModeName,
      onGoToSpider,
      onGoToNode,
      cancellable,
      onCancel,
      noNodeId,
    };
  },
});
